import React from "react";
import {
    Row,
    SubRow,
    SubTitle,
    LogoImg,
    GPLogoImg,
    MfaLogoImg,
    FPLogoImg,
    FPSubLogoImg,
    SPLogoImg,
    OSLogoImg,
    OSMobileLogoImg,
    SPSubLogoImg,
    VPLogoImg,
    StyledAnchor,
    SPLogoMobileImg,
    APLogoImg,
    APLogoMobileImg,
    GPSubLogoImg,
    GPSubLogoMobileImg,
    PartnerSponsorImg,
    StyledPartnerAnchor,
    JpLogoImg,
} from "./styles";
import Image from "./Images";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import PageContainer from "../PageContainer";
import PageTitle from "../PageTitle";

const Partners = () => {
    const image = Image();
    return (
        <PageContainer>
            <Breadcrumb>
                <BreadcrumbItem Link="/partners" Text="Partners" />
            </Breadcrumb>
            <PageTitle>Partners</PageTitle>
            <SubTitle>Presented by</SubTitle>
            <Row>
                <StyledAnchor
                    href="http://www.koreanculture.org.au"
                    target="_blank"
                >
                    <LogoImg fluid={image.kccauLogoImage} />
                </StyledAnchor>
            </Row>
            <SubTitle>Festival Partner</SubTitle>
            <Row>
                <StyledAnchor
                    href="http://overseas.mofa.go.kr/au-sydney-en/index.do"
                    target="_blank"
                >
                    <FPSubLogoImg fixed={image.cgroksImage} />
                </StyledAnchor>
                <StyledAnchor
                    href="https://alicesprings.nt.gov.au/"
                    target="_blank"
                >
                    <LogoImg fixed={image.alicespringsImage} />
                </StyledAnchor>
                <StyledAnchor
                    href="https://www.benalla.vic.gov.au/Home"
                    target="_blank"
                >
                    <LogoImg fixed={image.benellaImage} />
                </StyledAnchor>
                <StyledAnchor
                    href="https://www.burnside.sa.gov.au/Home"
                    target="_blank"
                >
                    <APLogoImg fixed={image.burnsideImage} />
                    <APLogoMobileImg fixed={image.burnsideMobileImage} />
                </StyledAnchor>
            </Row>
            <SubTitle>Venue Partners</SubTitle>
            <Row>
                <StyledAnchor
                    href="https://www.eventcinemas.com.au/"
                    target="_blank"
                >
                    <SPLogoImg fixed={image.eventImage} />
                </StyledAnchor>
                <StyledAnchor
                    href="https://www.palacecinemas.com.au/"
                    target="_blank"
                >
                    <SPLogoImg fixed={image.palaceImage} />
                </StyledAnchor>
                <StyledAnchor
                    href="https://www.theregaltheatre.com.au/"
                    target="_blank"
                >
                    <APLogoImg fixed={image.regalImage} />
                    <APLogoMobileImg fixed={image.regalMobileImage} />
                </StyledAnchor>
            </Row>
            <SubTitle>Media Partners</SubTitle>
            <Row>
                <StyledAnchor
                    href="https://originalspin.com.au/"
                    target="_blank"
                >
                    <OSLogoImg fixed={image.originalSpinImage} />
                    <OSMobileLogoImg fixed={image.originalSpinMobileImage} />
                </StyledAnchor>
                <StyledAnchor
                    href="http://gon-media.com/1_1.php"
                    target="_blank"
                >
                    <SPLogoImg fixed={image.gonImage} />
                </StyledAnchor>
            </Row>
            <SubTitle>Education Partners</SubTitle>
            <Row>
                <StyledAnchor href="https://www.unsw.edu.au/" target="_blank">
                    <SPLogoImg fixed={image.unswImage} />
                </StyledAnchor>
            </Row>
            <SubTitle>Sponsors</SubTitle>
            <Row>
                <StyledPartnerAnchor
                    href="https://english.visitkorea.or.kr/enu/KOO/KOO_EN_10_3.jsp"
                    target="_blank"
                >
                    <PartnerSponsorImg fluid={image.ktoFluidImage} />
                </StyledPartnerAnchor>
                <StyledPartnerAnchor
                    href="https://www.nongshim.com.au/"
                    target="_blank"
                >
                    <PartnerSponsorImg fluid={image.nongshimFluidImage} />
                </StyledPartnerAnchor>
                <StyledPartnerAnchor
                    href="https://flyasiana.com/C/AU/EN/index"
                    target="_blank"
                >
                    <PartnerSponsorImg fluid={image.asianaFluidImage} />
                </StyledPartnerAnchor>
                <StyledPartnerAnchor
                    href="https://www.ofoodglobal.com/"
                    target="_blank"
                >
                    <PartnerSponsorImg fluid={image.oFoodFluidImage} />
                </StyledPartnerAnchor>
                <StyledPartnerAnchor
                    href="https://www.jonggaglobal.com/"
                    target="_blank"
                >
                    <JpLogoImg fluid={image.jonggaFluidImage} />
                </StyledPartnerAnchor>
            </Row>
        </PageContainer>
    );
};

export default Partners;
