import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Partners from "../components/partners";

const ParntersPage = () => (
    <Layout>
        <SEO title="Partners" />
        <Partners />
    </Layout>
);

export default ParntersPage;
